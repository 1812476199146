@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes zoomOut {
    0% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.25);
    }
}

.zoom-in {
    animation: zoomIn 35s infinite;
}

.zoom-out {
    animation: zoomOut 35s infinite;
}
